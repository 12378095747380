import React from 'react';
import {translate} from "react-i18nify";

export const QuestionSlider = ({question, preAnswer, corner, response}) => {

    return <div className={"QuestionElement QuestionTextLine"}>
        <div className={"Question"} style={{marginBottom: 60}}>{question}</div>
        <div style={{float: "left"}}>{translate( corner.a )}</div>
        <div style={{float: "right"}}>{translate( corner.b )}</div>
        <div style={{width: "100%", marginTop: 100}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <input stlye={{webkitAppearance: "none !important", border: "none", borderBottom: "5px solid lawngreen"}}
                        className={"TextInput"} type={"range"} min={-100} max={100} defaultValue={preAnswer}
                        onChange={value => response(value.target.value)}/>
            </div>
        </div>
    </div>
}