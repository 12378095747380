import React, {useEffect, useRef, useState} from 'react';
import {Add, Delete, DeleteSmall} from "../../ApplicationFrame/Buttons";
import {translate} from "react-i18nify";
import PopUp from "../../ApplicationFrame/PopUp";
import InnerHTML from "../../ApplicationFrame/InnerHTML";

export const QuestionList = ({question, preAnswer, rules, response}) => {

    const refTextInput = useRef();
    const [list, setList] = useState(preAnswer || []);
    const [isNewValueValid, setIsNewValueValid] = useState(false);
    const [value, setValue] = useState("");
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        setIsNewValueValid( validateInput( value ) );
    }, [value, list]);

    const validateInput = value => {
        const newValue = "" + value.trim();
        const hasDuplications = list.reduce( (acc,cur) => {
            acc += cur.toLowerCase() === newValue.toLowerCase() ? 1 : 0;
            return acc;
        },0);

        const ready =
            ! hasDuplications
            && newValue.length >= rules["min-charlength"]
            && list.length < rules["entries"]["max"];
        return ready;
    }
    const onChange = element => {
        setValue( element.target.value );
    }

    const addToList = () => {
        if( isNewValueValid ){
            const newList = [...list];
            newList.push(value.trim());
            refTextInput.current.value = "";
            setValue( "" );
            newList.sort( (a,b) => a.localeCompare(b) );
            setList(newList);
            setIsNewValueValid( false );
            response( newList );
        }
    }
    const removeFromList = entry => {
        const newList = [...list.filter( a => a !== entry)];
        setList(newList);
        response( newList );
    }

    if( showInfo )
        setTimeout( () => document.getElementById("123").innerHTML = "<h3>lol</h3>", 1500 );
    // translate( "Sections.Help.exampleArtefacts" )

    return <div className={"QuestionElement"}>
        <div className={"Question"}>
            { showInfo &&
                <PopUp onClose={()=>setShowInfo(false)}>
                    <InnerHTML>{ translate( "Sections.Help.exampleArtefacts" ) }</InnerHTML>
                </PopUp>
            }
            { question }{ " (" + list.length + " " + translate("global.words.entries") + ")" }
            <div><button className={"tertiary"} onClick={()=>setShowInfo(true)}>{translate("Sections.Help.showHelp")}</button></div>
        </div>
        <div className={"QuestionAddList"}>
            {
                list.map( (entry,id) =>
                    <div key={id} className={"QuestionAddListEntry"}>
                        <DeleteSmall onClick={() => removeFromList(entry)} />{ entry }
                    </div>) }
            <input className={"TextInput"}
                   type={"text"} ref={refTextInput}
                   onKeyUp={ a => {
                       if( a.key.localeCompare("Enter") === 0 )
                           addToList();
                   }}
                   onChange={onChange} />
            <Add style={{float: "right", marginLeft: "20px"}} disabled={ ! isNewValueValid } onClick={addToList} />
        </div>
    </div>
}