import React, {useEffect, useState} from "react";

const InnerHTML = ({children}) => {

    // const ref = React.createRef();
    //
    const htmlDecode = content => {
        let e = document.createElement("div");
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    };
    //
    // useEffect(() => {
    //     setTimeout( () => {
    //         console.log( ref );
    //         if( ref?.current?.innerHTML === "" )
    //             ref.current.innerHTML = "<div>" + children + "</div>";
    //     } );
    // }, [ref]);
    // return <div ref={ref}></div>

    const [context, setContext] = useState("");

    useEffect( () => setContext( children ), [] );

    return <div contentEditable='false' dangerouslySetInnerHTML={{ __html: children }}/>;
}

export default InnerHTML;