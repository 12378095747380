import React from 'react';
import {LanguageProvider, Translation} from "./Translation";
import {LanguageSelect} from "./languageSelect";

export const STATUS = {
    "unknowned": "unknowend",
    "loading": "loading",
    "loaded": "loaded",
    "complete": "complete",
    "failed": "failed"
}

export const App = props =>
    <LanguageProvider>
        <Translation>
            <LanguageSelect/>{ props.children }
        </Translation>
    </LanguageProvider>;