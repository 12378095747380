import {XSmall} from "./Buttons";

const PopUp = ({children, onClose}) => {

    return <div style={{position: "absolute", display: "flex", backgroundColor: "transparent", width: "100%", top: "0", left: "0", height: "100%", alignItems: "center", justifyContent: "center"}}>
            <div style={{backgroundColor: "white", minWidth: "800px", maxWidth:"50%", marginTop: "2%", height: "80%", padding: "20px", borderRadius: "15px", overflowY: "auto",  zIndex: 20}}>
                <div style={{float: "right", zIndex: 22, height: 22}}><XSmall onClick={onClose} /></div>
                <div style={{marginTop: 40, color: "black", fontSize: "medium"}}>
                    { children }
                </div>
            </div>
    </div>
}

export default PopUp;